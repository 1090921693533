import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const assets = {
  we1: require('../resources/img/vp_woman_entrepreneur_1.jpeg'),
  bm2: require('../resources/img/vp_business_man_2.jpeg'),
  m1: require('../resources/img/vp_man_1.jpg'),
  developers: require('../resources/img/vp_developers.png'),
}

export default () => (
  <Root>
    <div className="clearfix">
      <div className="row">
        <div className="v-audience v-merchants col-4">
          <div className="v-picture">
            <img src={assets.bm2} alt=""/>
          </div>
          <div className="v-content">
            <h2>Marchands</h2>
            <p className="v-caption">
              Concentrez-vous sur votre business.
            </p>
            <p className="v-description">
              Vitepay vous aide à augmenter votre chiffre d'affaire grâce
              à des outils e-commerce, de paiement et de livraison simples et rapides.
            </p>
            <Link to="produits" className="v-cta">Decouvrez nos produits marchands</Link>
          </div>
        </div>
        <div className="v-audience v-clients col-4">
          <div className="v-content">
            <h2>Clients</h2>
            <p className="v-caption">
              Payez et faites-vous livrez en toute sérénité
            </p>
            <p className="v-description">
              Vitepay met en place une charte avec les marchands visant à protéger les consommateurs.
              Grâce à votre compte client, vous pouvez consulter tous vos paiements et gérez vos abonnements.
            </p>
            <Link className="v-cta" to="/support">En savoir plus sur vos droits</Link>
          </div>
          <div className="v-picture">
            <img src={assets.m1} alt=""/>
          </div>
        </div>
        <div className="v-audience v-business col-4">
          <div className="v-picture">
            <img src={assets.we1} alt=""/>
          </div>
          <div className="v-content">
            <h2>Entreprises</h2>
            <p className="v-caption">
              Digitalisez l'offre. Boostez la demande.
            </p>
            <p className="v-description">
              Les outils Entreprise Vitepay vous permettent d'accélérer vos processus de paiements
              et d'augmenter l'indice de satisfaction auprès de vos clients.
            </p>
            <Link className="v-cta" to="/produits#">
              Découvrez l'offre Entreprise
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="clearfix v-developers">
      <div className="clearfix text-center content">
        <h2>Les développeurs au coeur de notre stratégie</h2>
        <p>
          Vitepay soutient l'innovation et la communauté de développeurs
          en ouvrant notre API pour intégration dans vos applications. Nous nous occupons
          de toute la partie infrastructure de paiement pour que vous vous concentriez
          sur votre business.
        </p>
        <a title="API Vitepay" target="_blank"  rel="noopener noreferrer"  className="text-strong font-weight-bold" href="https://developers.vitepay.com">Accédez à
          l'API Vitepay</a>
      </div>
      <img className="hero" src={assets.developers} alt=""/>
    </div>
  </Root>
)

const Root = styled.div`
  .col-4 {
    padding: 0;
  }
  
  .v-developers {
    padding-top: 80px;
    
    .content {
      display: block;
      max-width: 800px;
      margin: auto;
      position: relative;
      z-index: 10;
      
      p {
        font-size: 1.6em;
      }
      
      a {
        padding: 25px;
        display:inline-block;
        margin-top: 30px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 1em;
        letter-spacing: 2px;
        color: inherit;
        background:#111;
        color: #FFF;
        
        &:hover {
          background: #1C4397;
          color: #FFF;
        }
      }
    }
    
    h2 {
      font-size: 3.6em;
      margin-bottom: 40px;
    }
    
    img.hero {
      margin-top: -150px;
    }
  }
  
  .v-audience {
    background: #E2E3E4;
    overflow: hidden;
    text-align: center;
    
    .v-content,
    .v-picture {
      height: 380px;
      overflow: hidden;
    }
    
    
    .v-content {
      padding: 40px 45px 30px;
      
      h2 {
        font-size: 2.5em;
        font-weight: 700;
      }
      
      .v-caption {
        font-weight: 500;
        font-size: 1.5em;
      }
      
      .v-description {
        padding-top: 20px;
        padding-bottom: 30px;
        font-size: 1.2em;
      }
      
      a {
        text-transform: uppercase;
        color: #FFF;
        border: 1px solid #FFF;
        padding: 20px;
        letter-spacing: 1px;
        border-radius: 2px;
        font-size: 0.9em;
        
        &:hover {
          background: #FFF;
          color: #363738;
        }
      }
      
    }
    
    &.v-merchants {
      background-image: linear-gradient(-125deg, #0AF 0%, #03F 100%);
      color: #FFF;
    }
    
    &.v-clients {
      background-image: linear-gradient(220deg, #FDBF1F 0%, #F8A010 100%);
      color: #FFF;
    }
    
    &.v-business {
      background-color: #000 !important;
      color: #FFF;
    }
    
  }
`
