import React from 'react'
import Layout from '../components/Layout'
import HomeMessage from '../components/HomeHero'
import Audience from '../components/Audience'

export default () => (
  <Layout page="home">
    <HomeMessage/>
    <Audience/>
  </Layout>
)
