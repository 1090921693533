import React from 'react'
import styled from 'styled-components'
import CTASignup from './CTASignup'

export default () => (
  <Root>
    <div className="container">
      <div className="text-center">
        <h1>Nous &oelig;uvrons au développement de<br /> l'e-commerce au Mali</h1>
        <p className="v-mission">
          Vitepay aide marchands, clients et entreprises à effectuer des
          transactions e-commerce simples et sécurisées.
        </p>
        <CTASignup />
      </div>
    </div>
  </Root>
)

const Root = styled.div`
  position: relative;
  padding: 110px 0 140px;
  
  
  h1 {
    font-size: 3.8em;
    font-weight: bold;
  }
  
  .v-mission {
    padding: 10px 0 30px;   
    font-size: 1.8em;
    line-height: 1.4;
  }
  

`
